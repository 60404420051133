import Swiper, { Navigation, Pagination } from 'swiper';

const swiper = new Swiper('.stories-swiper', {
  modules: [Navigation, Pagination],
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  draggable: true,
});
